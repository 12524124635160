import React from 'react';
import { graphql } from 'gatsby';
import propTypes from 'prop-types';

import SEO from '@components/seo';

import Contact from '../../../components/contact';
import Hero from '../../../components/hero';
import Layout from '@components/layout';
import MarkdownContent from '../../../components/markdown-content/MarkdownContent';

const SekkiRockerPay = ({ data }) => {
  const headLine = data.contentfulPage.headLine;
  const bodyText = data.contentfulPage.pageContent[6].bodyText.bodyText;

  return (
    <Layout>
      <SEO title={headLine} noIndex />
      <Hero
        breadcrumbs={[
          { path: '/', name: 'Hem' },
          { path: '/sekki', name: 'SEKKI' },
        ]}
        title={headLine}
      />
      <div id="first">
        <MarkdownContent markdown={bodyText} />
      </div>
      <Contact hideFAQ />
    </Layout>
  );
};

export default SekkiRockerPay;

SekkiRockerPay.propTypes = {
  data: propTypes.object,
};

export const query = graphql`
  query SekkiRockerPayDelbetalningQuery {
    contentfulPage(pageUrl: { eq: "/sekki" }) {
      headLine

      pageContent {
        ... on ContentfulDescriptionComponent {
          id
          bodyText {
            bodyText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
